import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonials from "../components/testimonials"
import Loadable from "@loadable/component"
import { Link } from "gatsby";
import Img from 'gatsby-image/withIEPolyfill'

const IndexPage = ({data}) => {
     
    const HomeTopComponent = Loadable(() => import("../components/hometop"));

    return (
        <Layout pageClass="home-page">
        <SEO title="Home" description="Modus Developments for high quality new builds, additions and renovations and reclads in Auckland, New Zealand." />
            <div className="home-top-container">
                <HomeTopComponent />
           </div>
            <div className="our-work-preview typography">
                <div className="inner">
                    <div className="our-work our-work-1">
                        <Link to="/new-builds">
                            <div className="image"> 
                                <Img 
                                    fixed={data.newBuildsImage.fixed}
                                    alt={'New Builds'}
                                    objectFit="cover"
                                    objectPosition="50% 50%"
                                    style={{
                                            width:'100%',
                                            height: '100%',
                                            display:'block',
                                            position: 'absolute',
                                            top: '0'

                                        }}              
                                />
                            </div>
                            <h3>New Builds</h3>
                        </Link>
                        <p className="full">You’ve created a unique design. Modus Developments will help you make it a reality, and minimise any surprises along the way.</p>
                    </div>
                    <div className="our-work our-work-2">
                        <Link to="/additions-and-renovations">
                            <div className="image">
                                <Img 
                                    fixed={data.additionsRenovationsImage.fixed}
                                    alt={'New Builds'}
                                    objectFit="cover"
                                    objectPosition="50% 50%"
                                    style={{
                                            width:'100%',
                                            height: '100%',
                                            display:'block',
                                            position: 'absolute',
                                            top: '0'

                                        }}              
                                />

                            </div>
                            <h3>Additions and Renovations</h3>
                        </Link>
                        <p className="full">Experience counts when you’re extending or renovating. Our experienced team will work with you to deliver a result you’re proud of.</p>
                    </div>
                    <div className="our-work our-work-3">
                        <Link to="/reclads">
                            <div className="image">
                                <Img 
                                    fixed={data.recladsImage.fixed}
                                    alt={'New Builds'}
                                    objectFit="cover"
                                    objectPosition="50% 50%"
                                    style={{
                                            width:'100%',
                                            height: '100%',
                                            display:'block',
                                            position: 'absolute',
                                            top: '0'

                                        }}              
                                />
                            </div>
                            <h3>Reclads</h3>
                        </Link>
                        
                        <p className="full">Add value and improve the performance of a dated or flawed home. Work with an expert team that takes care of all the details.</p>
                    </div>
                </div>
                

            </div>
            <Testimonials />
        </Layout>
    )
}

export const pageQuery = graphql`
     {
        newBuildsImage: imageSharp(resolutions: {originalName: {eq: "Karaka-3.jpg"}}) { 
          fixed (width: 420, height: 336, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
            }
           
        }
        additionsRenovationsImage: imageSharp(resolutions: {originalName: {eq: "richmond-living-area.jpg"}}) { 
            fixed (width: 420, height: 336, quality: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
              }
             
          }

        recladsImage: imageSharp(resolutions: {originalName: {eq: "_43A2116.jpg"}}) { 
            fixed (width: 420, height: 336, quality: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
              }
             
          }
    
    }
`


export default IndexPage