import React from 'react'

const Testimonials = () => {

    return (

        <div className="testimonial">
            <div className="inner">
                <div className="bg-wrapper">
                    <blockquote>
                        <span>We were looking for a high-quality build of an architecturally designed house with a number of bespoke features. The result is outstanding in terms of the quality of the build, but also the whole experience of working with Modus.</span>

                        <cite>- Michele and Barry</cite>
                    </blockquote>

                </div>
            
            
            

            </div>
        </div>
       
    )

}

export default Testimonials